import jwtDecode from "jwt-decode"

import { getCookie } from "@mkt/utils/cookie"

import { categories, maxAgeInSeconds } from "../../constants"

import getTrackingPartner from "../../helpers/getTrackingPartner"

export const getFilters = (items) => {
  let listName = ""
  const filters = items.reduce((filters, item) => {
    const options = [...item.querySelectorAll("input:checked")]
    const filteredOptions = options.map((input) => {
      const { title, list } = JSON.parse(input.dataset.infos)
      listName = list
      return {
        type: title,
        value: input.value,
      }
    })

    return {
      ...filters,
      ...filteredOptions,
    }
  }, {})

  return {
    filters,
    listName,
  }
}

const getId = (product) => {
  if (product?.full_slug) {
    return product.full_slug
      .replace("/cartao-de-credito/", "")
      .replace("/", "")
      .replace("products", "")
  }

  if (product?.id) {
    return product.id.replace("cartao-", "").replace("products", "")
  }

  if (product?.productId) {
    return product?.productId.replace("products", "")
  }

  return product?.slug?.replace("products", "")
}

export const getProductData = (product, index) => {
  const { pathname } = window.location
  const pageId =
    pathname === "/"
      ? "home"
      : pathname.replaceAll("/", "-").replace(/\-?(\D+)\-/, "$1")

  return {
    brand: product?.brand || product?.issuer || product?.partner || "",
    category: product?.category?.toString() || categories.creditCard || "",
    name: product?.title || product?.name || "",
    variant: product?.variant || "",
    pageId: pageId || "",
    productId: getId(product) || "",
    position: parseInt(index),
  }
}

export const getProductDataMastercard = (product, index) => {
  const { pathname } = window.location
  const pageId =
    pathname === "/"
      ? "home"
      : `mastercard-${pathname.replace(/\/$/, "").split("/").pop()}`

  return {
    brand: product?.brand || product?.issuer || product?.partner || "",
    category: categories.creditCards || "",
    name: product?.title || product?.name || "",
    variant: product?.variant || "",
    pageId: pageId || "",
    productId: product?.product_slug || "",
    position: parseInt(index),
  }
}

export const getDecodedFireboltStore = (partner) => {
  const fireboltStore = JSON.parse(localStorage.getItem("firebolt-store"))
  const partnerData = fireboltStore?.sessionKeys[partner]
  return !!partnerData ? jwtDecode(partnerData) : {}
}

export const getCorrelationId = () => {
  const uuid = getCookie("correlationId")
  const trackingPartner = getTrackingPartner()
  const correlationId =
    getDecodedFireboltStore(trackingPartner)?.unique_id || ""

  if (!uuid) {
    document.cookie = `correlationId=${correlationId}; max-age=${maxAgeInSeconds};`
  }

  return correlationId
}
